/* eslint-disable import/no-anonymous-default-export */
import { 
    ROW_DATA_CHANGED, 
    ROW_SELECTION_CHANGED, 
    AG_GRID_PASS_THROUGH,
    STORE_SCOPE_ROW_DATA,
    STORE_SCOPE_COL_DEFS,
    STORE_STATUS_ROW_DATA,
    STORE_STATUS_COL_DEFS,    
    STORE_BUDGET_ROW_DATA,
    STORE_BUDGET_COL_DEFS,
    STORE_COMMITMENT_ROW_DATA,
    STORE_COMMITMENT_COL_DEFS,
    STORE_PDT_ROW_DATA,
    STORE_PDT_COL_DEFS,
    STORE_MISC_COL_DEFS,
    STORE_MISC_ROW_DATA,
    STORE_OUTSRC_COL_DEFS,
    STORE_OUTSRC_ROW_DATA,
    FLUSH_STORED_GRID_INFO,
    STORE_MS_COL_DEFS,
    STORE_MS_ROW_DATA,
} from '../actions/_types'

const initialState = {
    rowData: [],
    columnDefs: [],
    userRowSelection: [],
    budgetRowData: [],
    budgetColDefs: [],
    scopeRowData: [],
    scopeColDefs: [],
    statusRowData: [],
    statusColDefs: [],
    commitmentRowData: [],
    commitmentColDefs: [],
    pdtRowData: [],
    pdtColDefs: [],
    miscRowData: [],
    miscColDefs: [],
    outSrcRowData: [],
    outSrcColDefs: [],
    msRowData: [],
    msColDefs: [],  
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ROW_DATA_CHANGED:
            return { 
                ...state, 
                rowData: action.rowData 
            };
        case ROW_SELECTION_CHANGED:
            return { 
                ...state, 
                userRowSelection: action.userRowSelection 
            };                           
        case AG_GRID_PASS_THROUGH:
            return {
                ...state
            };
        case STORE_SCOPE_COL_DEFS:
            return {
                ...state, 
                scopeColDefs: action.scopeColDefs
            };
        case STORE_SCOPE_ROW_DATA:
            return {
                ...state, 
                scopeRowData: action.scopeRowData
            };
        case STORE_STATUS_COL_DEFS:
            return {
                ...state, 
                statusColDefs: action.statusColDefs
            };
        case STORE_STATUS_ROW_DATA:
            return {
                ...state, 
                statusRowData: action.statusRowData
            };            
        case STORE_BUDGET_COL_DEFS:
            return {
                ...state, 
                budgetColDefs: action.budgetColDefs
            };
        case STORE_BUDGET_ROW_DATA:
            return {
                ...state, 
                budgetRowData: action.budgetRowData
            };
        case STORE_COMMITMENT_COL_DEFS:
            return {
                ...state, 
                commitmentColDefs: action.commitmentColDefs
            };
        case STORE_COMMITMENT_ROW_DATA:
            return {
                ...state, 
                commitmentRowData: action.commitmentRowData
            };
        case STORE_PDT_COL_DEFS:
            return {
                ...state, 
                pdtColDefs: action.pdtColDefs
            };
        case STORE_PDT_ROW_DATA:
            return {
                ...state, 
                pdtRowData: action.pdtRowData
            };  
        case STORE_MISC_COL_DEFS:
            return {
                ...state, 
                miscColDefs: action.miscColDefs
            };
        case STORE_MISC_ROW_DATA:
            return {
                ...state, 
                miscRowData: action.miscRowData
            };
        case STORE_OUTSRC_COL_DEFS:
            return {
                ...state, 
                outSrcColDefs: action.outSrcColDefs
            };
        case STORE_OUTSRC_ROW_DATA:
            return {
                ...state, 
                outSrcRowData: action.outSrcRowData
            };
        case FLUSH_STORED_GRID_INFO:
            return {
                ...state,
                ...initialState 
            };
        case STORE_MS_COL_DEFS:
            return {
                ...state, 
                msColDefs: action.msColDefs
            };
        case STORE_MS_ROW_DATA:
            return {
                ...state, 
                msRowData: action.msRowData
            };                                                
        default:
            return state
    }
};
