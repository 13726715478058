import {
    keys,
    startCase,
    forOwn,
    isEmpty,
    indexOf,
    split,
    pullAll,
    each,
    remove,
    find,
    replace,
    uniq,
    toUpper,
    flatten,
    has,
    map,
    } from 'lodash'

const filterColumns = (response = {}, readInSections = [], incSection = false, editable = false, alt = false) => {
    // Add items to this list that you do not want displayed

    const filter = [
        'tag',
        'id',
        'mbId',
        'owId',
        'code',
        'employeeId',
        'email',
        'taskId',
        'subtaskId',
        'productId',
        'deliverableId',
        'taskNumber',
        'officeCode',
        'rootTaskId',
        'activeFlag',
        'overheadRate',
        'rate',
        'reviewer',
        'availability',
        'deliverableNumber',
        'taskNumber',
        'subtaskNumber',
        'dependancyTaskId',
        'dependancyAnchor',
        'targetAnchor',
        'targetTaskId',
        'bondId',
        'lagCode',
        'n',
        'wbsId',
        'wbsLevel',
        'wbsOrder',
        'parentId',
        'bondPriorInd',
        'p2Id',
        'lev',
        'sourceFoa',
        'activeInactiveInd',
        'hier',
        'heir',
        'tracked',
        'pracNo',
        'pracLine',
        'hierRef',
    ] // eslint-disable-line
    // Used for comparison on section columns, need to make this more efficient eventually.
    // const sectionList = readInSections()
    const chkOrg = has(readInSections[0], 'orgCode')
    const sectionAndOrgs = (chkOrg) ? readInSections : []
    const sectionList = (chkOrg) ? map(readInSections, 'sectionCode') : readInSections
    const districtList = isDistrict(sectionList) // Try to future proof the comparison

    // Gather header names
    // Check to see if headers are seperated from row-data where header key will equal row-data field_name
    // Not full map included in header, will use as a lookup.
    let empIdHeader = []
    let inKeys = []

    if(has(response, 'data')) {
        if(has(response.data, 'Header')){ 
            empIdHeader = response.data.Header
        }
        if(has(response.data, 'Data')) {
            inKeys = keys(response.data.Data[0])
        } else {
            inKeys = keys(response.data[0])
        }
    }

    if(isEmpty(empIdHeader) && isEmpty(inKeys)) {
        // console.error('No Column Information Available')
        return []
    }

    // Try to track group / child creation blindly
    let tmpCnt = 0
    let tmpLastGroupName = ''
    let tmpGroupHolder = [] // Now not blindly
    let estWidth = 0

    const columnNames = inKeys.map(inName => { // eslint-disable-line
        const strCaseHeaderName = startCase(inName)
        const doHide = (filter.indexOf(inName) > -1) // If columnname exists in the filter array mark hide = true
    // Column definitions
        switch (inName) {
            case 'id': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }
            case 'row': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 65, hide: doHide})
            }
            case 'origin': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 75, hide: doHide})
            }
            case 'type': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 35, hide: doHide})
            }
            case 'info': {
                return ({headerName: 'Information', headerTooltip: 'Information', field: inName, width: 150, hide: doHide})
            }
            case 'n': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, hide: doHide})
            }
            case 'timestamp': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 85, hide: doHide})
            }
            case 'tag': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }
            case 'mbId': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            } 
            case 'owId': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            } 
            case 'code': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            } 
            case 'deliverableNumber': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            } 
            case 'taskNumber': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }                         
            case 'subtaskNumber': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }             
            case 'subtaskId': { 
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }                      
            case 'email': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 275, hide: doHide})
            }
            case 'parentId': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,  
                    field: inName,  
                    hide: doHide
                })
            }  
            case 'anchor': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName, 
                    field: inName,
                    width: 100,  
                    hide: doHide
                })
            }  
            case 'overrideSchedule': {
                return ({
                    headerName: (alt) ? 'OR' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'Override Schedule' : strCaseHeaderName, 
                    field: inName,
                    width: 100,  
                    hide: doHide
                })
            }  
            case 'bondPriorInd': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName, 
                    field: inName, 
                    hide: doHide
                })
            }  
            case 'ovrStartDate': {
                return ({
                    headerName: (alt) ? 'OR Start' : strCaseHeaderName, 
                    headerTooltip: (alt) ? 'Override Start Date' : strCaseHeaderName,
                    field: inName, 
                    width: 110, 
                    hide: doHide
                })
            }  
            case 'ovrEndDate': {
                return ({
                    headerName: (alt) ? 'OR End' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'Override End Date' : strCaseHeaderName,
                    field: inName, 
                    width: 110, 
                    hide: doHide
                })
            }  
            case 'ovrDurationWd': {
                return ({
                    headerName: (alt) ? 'OR WD' :strCaseHeaderName,
                    headerTooltip: (alt) ? 'Override Duration Work Days' : strCaseHeaderName, 
                    field: inName,
                    width: 100,  
                    hide: doHide
                })
            }  
            case 'ovrDurationCd': {
                return ({
                    headerName: (alt) ? 'OR CD' :strCaseHeaderName,
                    headerTooltip: (alt) ? 'Override Duration Calendar Days' : strCaseHeaderName, 
                    field: inName,
                    width: 100,  
                    hide: doHide
                })
            }  
            case 'reviewer': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName, 
                    field: inName, 
                    width: 90, 
                    hide: doHide, 
                    editable: editable,
                    suppressSizeToFit: true, 
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['F', 'T']
                    }
                })
            }                       
            case 'role': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    width: 85, 
                    hide: doHide, 
                    editable: editable,
                    suppressSizeToFit: true, 
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['ACO', 'COR', 'PDT', 'PM', 'Reviewer', 'TL']
                    }
                })
            }            
            case 'loadingCurve': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName, 
                    field: inName, 
                    width: 135, 
                    hide: doHide, 
                    editable: editable,
                    suppressSizeToFit: true, 
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['Back_Loaded', 'Bell_Shaped', 'Flat', 'Front_Loaded']
                    }
                })
            }                                    
            case 'displayName': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    width: 300, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }            
            case 'rate': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 25, hide: doHide})
            }           
            case 'overheadRate': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 25, hide: doHide})
            }
            case 'availability': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 110, hide: doHide})
            }                                    
            case 'employeeId': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 100, hide: doHide})
            }                                                                          
            case 'taskId': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 100, hide: doHide})
            }            
            case 'productId': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 150, hide: doHide})
            }         
            case 'deliverableId': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 150, hide: doHide})
            }          
            case 'sectionCode': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    width: 175, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }
            case 'orgCode': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    width: 100, 
                    hide: doHide,
                    suppressSizeToFit: true,
                })
            }          
            case 'officeCode': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName, 
                    field: inName, 
                    width: 175, 
                    hide: doHide
                })
            }                                     
            case 'taskName': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName, 
                    field: inName, 
                    width: 300, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                    cellStyle: {
                        textAlign: 'left'
                    }

                })
            }    
            case 'title': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    width: 300, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                    cellStyle: {
                        textAlign: 'left'
                    }

                })
            }           
            case 'earnedValue': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    width: 50, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                    cellStyle: {
                        textAlign: 'left'
                    }

                })
            }   
            case 'percentComplete': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                   // width: 50, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                    cellStyle: {
                        textAlign: 'left'
                    }

                })
            }             
            case 'notes': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    width: 500, 
                    hide: doHide, 
                    editable: editable,
                    cellStyle: {
                        textAlign: 'left'
                    }, 
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 2000
                    }
                })
            }                        
            case 'generalScope': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    width: 400, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,                     
                    cellStyle: {
                        textAlign: 'left'
                    },
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 2000
                    },                    

                })
            }
            case 'generalNotes': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    // width: 400, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,                     
                    cellStyle: {
                        textAlign: 'left'
                    },
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 2000
                    },                    

                })
            }                       
            case 'activeFlag': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    width: 150, 
                    hide: doHide, 
                    editable: editable
                })
            }
            case 'trackingInd': {
                return ({
                    headerName: 'Tracked', 
                    headerTooltip: 'Tracking Indicator',
                    field: inName, 
                    width: 50, 
                    hide: doHide, 
                    editable: editable
                })
            }              
            case 'rootTaskId': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 150, hide: doHide})
            }          
            case 'lineItem': {
                return ({
                    headerName: alt ? 'Task Name' : 'Line Item', 
                    headerTooltip: alt ? 'Task Name' : 'Line Item',
                    field: inName, 
                    width: 300, 
                    hide: doHide,
                    editable: false,
                   // suppressSizeToFit: true,
                    cellStyle: {
                        textAlign: 'left'
                    }
                })
            }
            case 'p2Id': { 
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            } 
            case 'p2Number': {
                return ({
                    headerName: alt ? 'P2 Number' : 'P2 Number', 
                    headerTooltip: alt ? 'P2 Number' : 'P2 Number',
                    tooltipField: inName,
                    field: inName, 
                    width: 120, 
                    hide: doHide,
                    editable: false,
                   // suppressSizeToFit: true,
                    cellStyle: {
                        textAlign: 'left'
                    }
                })
            }
            case 'projectNo': {
                return ({
                    headerName: alt ? 'P2 Number' : 'P2 Number', 
                    headerTooltip: alt ? 'P2 Number' : 'P2 Number',
                    tooltipField: inName,
                    field: inName, 
                    width: 120, 
                    hide: doHide,
                    editable: false,
                   // suppressSizeToFit: true,
                    cellStyle: {
                        textAlign: 'left'
                    }
                })
            }              
            case 'p2ProjNa': {
                return ({
                    headerName: alt ? 'Project Title' : 'Project Title', 
                    headerTooltip: alt ? 'Project Title' : 'Project Title',
                    tooltipField: inName,
                    field: inName, 
                    width: 400, 
                    hide: doHide,
                    editable: false,
                   // suppressSizeToFit: true,
                    cellStyle: {
                        textAlign: 'left'
                    }
                })
            }                       
            case 'total': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                    cellStyle: {
                        textAlign: 'center' 
                    }
                })
            }
            case 'laborTotal': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                    cellStyle: {
                        textAlign: 'center' 
                    }
                })
            }  
            case 'budgetTotal': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    suppressSizeToFit: true,
                    cellStyle: {
                        textAlign: 'center' 
                    }
                })
            }            
            case 'startDate': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    width: 110, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true
                })
            }            
            case 'endDate': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    width: 110, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true
                })
            }
            case 'oldStartDate': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    width: 125, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true
                })
            }            
            case 'oldEndDate': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    width: 125, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true
                })
            }            
            case 'statusDate': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName, 
                    field: inName, 
                   // width: 100, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true
                })
            } 
            case 'durationWd': {
                return ({
                    headerName: (alt) ? 'WD' : strCaseHeaderName, 
                   // headerTooltip: (alt) ? 'WD' : strCaseHeaderName,
                    field: inName, 
                    width: 65, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true
                })
            } 
            case 'durationCd': {
                return ({
                    headerName: (alt) ? 'CD' : strCaseHeaderName, 
                  //  headerTooltip:  (alt) ? 'CD' : strCaseHeaderName,
                    field: inName, 
                    width: 65, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true
                })
            }
            case 'oldDurationWd': {
                return ({
                    headerName: (alt) ? 'old WD' : strCaseHeaderName, 
                   // headerTooltip: (alt) ? 'WD' : strCaseHeaderName,
                    field: inName, 
                    width: 85, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true
                })
            } 
            case 'oldDurationCd': {
                return ({
                    headerName: (alt) ? 'old CD' : strCaseHeaderName, 
                  //  headerTooltip:  (alt) ? 'CD' : strCaseHeaderName,
                    field: inName, 
                    width: 85, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true
                })
            }
            case 'milestone': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,  
                    field: inName, 
                    width: 100, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true
                })
            }  
            case 'successors': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,  
                    field: inName, 
                    width: 300, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true
                })
            }                   
            case 'wbs': {
                return ({
                    headerName: 'Task ID',
                    headerTooltip: 'Task ID',  
                    field: inName,
                    width: 85, 
                    hide: doHide,
                    suppressSizeToFit: true,
                    cellStyle: {
                        textAlign: 'left'
                    }
                })
            }
            case 'pracNo': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,  
                    field: inName,
                    width: 85, 
                    hide: doHide,
                })
            }
            case 'pracLine': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,  
                    field: inName,
                    width: 85, 
                    hide: doHide,
                })
            }                              
            case 'days': {
                return ({
                    headerName: 'Days Type',
                    headerTooltip: 'Days Type',  
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    editable: editable,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['Work Days', 'Calendar Days']
                    }, 
                })
            }
            case 'inhouse': {
                return ({
                    headerName: 'In House',
                    headerTooltip: 'Can Bring In House', 
                    field: inName, 
                    width: 100, 
                    hide: doHide,
                    editable: editable,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['T', 'F']
                    }, 
                })
            } 
            case 'contractType': {
                return ({
                    headerName: 'Contract Type',
                    headerTooltip: 'Contract Type', 
                    field: inName, 
                    width: 130, 
                    hide: doHide,
                    editable: editable,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['GSA', 'HDC', 'IPA', 'MIPRS | EAO', 'Modification', 'Other District', 'PEP', 'Small Business', 'Sole Source', 'Task Order', 'Other']
                    }, 
                })
            }  
            case 'lag': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName, 
                    field: inName, 
                    width: 85, 
                    hide: doHide,
                    editable: editable,
                    cellStyle: {
                        textAlign: 'left'
                    }
                })
            }             
            case 'lagCode': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,
                    field: inName, 
                    width: 85, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['WD', 'CD']
                    }, 
                })
            } 
            case 'bondDisplay': {
                return ({
                    headerName: 'Type',
                    headerTooltip: 'Type', 
                    field: inName, 
                    width: 170, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['Finish-to-Start (FS)', 'Finish-to-Finish (FF)', 'Start-to-Finish(SF)', 'Start-to-Start (SS)',
                                'Finish-to-Start (FS) Linked', 'Finish-to-Finish (FF) Linked', 'Start-to-Finish(SF) Linked', 'Start-to-Start (SS) Linked', 'Linked']
                    },                     
                })
            } 
            case 'bondId': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName, 
                    field: inName, 
                    width: 85, 
                    hide: doHide,
                    suppressSizeToFit: true,
                    cellStyle: {
                        textAlign: 'left'
                    }
                })
            }
            case 'dependancyAnchor': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName, 
                    field: inName, 
                    width: 85, 
                    hide: doHide,
                    editable: editable,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['FINISH', 'START']
                    }, 
                })
            }  
            case 'targetAnchor': {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName, 
                    field: inName, 
                    width: 85, 
                    hide: doHide,
                    editable: editable,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['FINISH', 'START']
                    }, 
                })
            }
            case 'targetTaskId': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 75, hide: doHide})
            }
            case 'dependancyTaskId': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 75, hide: doHide})
            }
            case 'versionNotes': {
                return ({
                    headerName: (alt) ? 'Notes' : strCaseHeaderName,
                    headerTooltip: strCaseHeaderName, 
                    field: inName, 
                    width: 400, 
                    hide: doHide,
                    editable: editable,
                })
            }
            case 'scopeVersionDate': {
                return ({
                    headerName: (alt) ? 'Action Date' : strCaseHeaderName,
                    headerTooltip: strCaseHeaderName, 
                    field: inName, 
                    width: 110, 
                    hide: doHide,
                    editable: editable,
                })
            }

            case 'dollars': {
                return ({
                    headerName: (alt) ? 'Cost ($)' : strCaseHeaderName,
                    headerTooltip: strCaseHeaderName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    editable: editable,
                })
            }
            case 'hours': {
                return ({
                    headerName: (alt) ? 'Cost (hrs)' : strCaseHeaderName,
                    headerTooltip: strCaseHeaderName, 
                    field: inName, 
                    width: 110, 
                    hide: doHide,
                    editable: editable,
                })
            }            
            case 'wbsId': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }
            case 'wbsLevel': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }
            case 'wbsOrder': {
                return ({headerName: strCaseHeaderName, headerTooltip: strCaseHeaderName, field: inName, width: 50, hide: doHide})
            }
            case 'wiCode': {
                return ({
                    headerName: (alt) ? 'Work Item' : strCaseHeaderName, 
                    headerTooltip: (alt) ? 'Work Item' : strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 100, 
                    hide: doHide
                })
            }
            case 'sourceFoa': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 50, 
                    hide: doHide
                })
            }
            case 'lev': {
                return ({
                    headerName: (alt) ? 'Level' : strCaseHeaderName, 
                    headerTooltip: (alt) ? 'Level' : strCaseHeaderName,
                    tooltipField: inName,
                    field: inName, 
                    width: 50, 
                    hide: doHide
                })
            }
            case 'wiCodeLev': {
                return ({
                    headerName: (alt) ? 'Work Item' : strCaseHeaderName, 
                    headerTooltip: (alt) ? 'Work Item' : strCaseHeaderName,
                    tooltipField: inName,
                    field: inName, 
                    width: 300, 
                    hide: doHide,
                })
            }            
            case 'wiName': {
                return ({
                    headerName: (alt) ? 'Work Item Name' : strCaseHeaderName, 
                    headerTooltip: (alt) ? 'Work Item Name' : strCaseHeaderName,
                    tooltipField: inName,
                    field: inName, 
                    width: 500, 
                    hide: doHide
                })
            }            
            case 'activeInactiveInd': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 50, 
                    hide: doHide
                })
            }
            case 'inactiveInd': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 80, 
                    hide: doHide
                })
            }               
            case 'hier': {
                return ({
                    headerName: (alt) ? 'Hierarchy' : strCaseHeaderName, 
                    headerTooltip: (alt) ? 'Hierarchy' : strCaseHeaderName,
                    tooltipField: inName,
                    field: inName, 
                    width: 300, 
                    hide: doHide
                })
            }
            case 'heir': { // Typo compensation
                return ({
                    headerName: (alt) ? 'Hierarchy' : strCaseHeaderName, 
                    headerTooltip: (alt) ? 'Hierarchy' : strCaseHeaderName,
                    tooltipField: inName,
                    field: inName, 
                    width: 300, 
                    hide: doHide
                })
            }
            case 'hierRef': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName,
                    field: inName, 
                    width: 200, 
                    hide: doHide
                })
            }
            case 'projectName': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName,
                    field: inName, 
                    width: 400, 
                    hide: doHide
                })
            }
            case 'descrip': {
                return ({
                    headerName: (alt) ? 'Item Description' : strCaseHeaderName, 
                    headerTooltip: (alt) ? 'Item Description' : strCaseHeaderName,
                    tooltipField: inName,
                    field: inName, 
                    width: 400, 
                    hide: doHide
                })
            }
            case 'agP2': {
                return ({
                    headerName: 'P2', 
                    headerTooltip: 'P2',
                    tooltipField: inName,
                    field: inName, 
                    width: 400, 
                    hide: doHide
                })
            }
            case 'agFnd': {
                return ({
                    headerName: 'Fund Account', 
                    headerTooltip: 'Fund Account',
                    tooltipField: inName,
                    field: inName, 
                    width: 400, 
                    hide: doHide
                })
            }
            case 'agWI': {
                return ({
                    headerName: 'Work Item', 
                    headerTooltip: 'Work Item',
                    tooltipField: inName,
                    field: inName, 
                    width: 350, 
                    hide: doHide
                })
            }
            case 'agCode': {
                return ({
                    headerName: 'Charge Code / PR&C', 
                    headerTooltip: 'Charge Code / PR&C',
                    tooltipField: inName,
                    field: inName, 
                    width: 400, 
                    hide: doHide
                })
            }
            case 'prodID': {
                return ({
                    headerName: 'Product ID', 
                    headerTooltip: 'Product ID',
                    tooltipField: inName,
                    field: inName, 
                    width: 50, 
                    hide: true
                })
            }
            case 'ts': {
                return ({
                    headerName: 'Time Stamp', 
                    headerTooltip: 'Time Stamp',
                    tooltipField: inName,
                    field: inName, 
                    width: 50, 
                    hide: true
                })
            }
            case 'series': {
                return ({
                    headerName: 'Series', 
                    headerTooltip: 'Series',
                    tooltipField: inName,
                    field: inName, 
                    width: 50, 
                    hide: true
                })
            }
            case 'tracked': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName,
                    field: inName, 
                    width: 50, 
                    hide: doHide
                })
            }
            case 'chargeCode': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName,
                    field: inName, 
                    width: 200, 
                    hide: doHide
                })
            }
            
            default: {
                let tmpInName = inName 
                forOwn(empIdHeader, (value, key) => {
                    if(inName === key) { 
                        return inName = value
                    }
                })

                const tmpIsOs = isOfficeSymbol(inName, districtList) // 

                if(tmpIsOs.isbool && incSection === true) {

                        let tmpSplitNames = split(inName, ' ', 10)

                         forOwn(tmpSplitNames, (value, key) => {
                            (isEmpty(value)) ? tmpSplitNames[key] = '' : tmpSplitNames[key] = value
                        })
                        
                        if(indexOf(tmpSplitNames[0], '-') === -1) {
                            if(tmpIsOs.group !== tmpSplitNames[0]) {
                                tmpSplitNames[0] = tmpIsOs.group
                            }
                            let tmpIdx = split(tmpIsOs.group, ' ')
                            tmpSplitNames = pullAll(tmpSplitNames, tmpIdx)                        
                        }

                        let tmpHeaderName = ''
                        let ctrlColGroupShow = 'open'
                        let tmpStructure = {}                        
                    
                        if(tmpLastGroupName !== tmpSplitNames[0]) { 

                            if(!isEmpty(tmpLastGroupName) && 
                                (tmpLastGroupName !== tmpSplitNames[0]) && 
                                (tmpGroupHolder.indexOf(tmpSplitNames[0]) === -1)) { 
                                    tmpCnt = 0
                            }                            
                            tmpLastGroupName = tmpSplitNames[0]
                        }
                        tmpCnt++
                        if(tmpCnt === 1 && tmpGroupHolder.indexOf(tmpSplitNames[0] === -1)) {
                            tmpGroupHolder.push(tmpSplitNames[0]) 
                            tmpHeaderName = estHeader(sectionList, tmpSplitNames)
                            let estWidth
                            (tmpSplitNames[0].length > tmpHeaderName.length) ? estWidth = estColLength(`${tmpSplitNames[0]}`) : estWidth = estColLength(`${tmpHeaderName}`)
                            tmpStructure = {
                                headerName: tmpSplitNames[0],
                                groupId: tmpSplitNames[0],
                                openByDefault: true,
                                marryChildren: true,
                                width: estColLength(`${tmpSplitNames[0]}`),
                                children: [{
                                    joinOn: tmpSplitNames[0], 
                                    headerName: `${tmpHeaderName}`, 
                                    field: tmpInName,
                                    width: estWidth,
                                    editable: editable,
                                    hide: false,
                                    cellStyle: {
                                        textAlign: 'center' 
                                    } 
                                }]
                            }
                        } else {
                            tmpHeaderName = estHeader(sectionList, tmpSplitNames)
                            estWidth = estColLength(`${tmpHeaderName}`)
                            tmpStructure = {
                                joinOn: tmpSplitNames[0],                                    
                                headerName: `${tmpHeaderName}`,
                                field: tmpInName,
                                width: estWidth,
                                editable: editable,
                                hide: false,
                                columnGroupShow: `${ctrlColGroupShow}`,
                                filter: 'agNumberColumnFilter', 
                                cellStyle: {
                                    textAlign: 'center' 
                                },
                            }
                        }
                        return tmpStructure                            
                    //}
                } else {
                    const tmpIsOC =  isOfficeCode(sectionList, tmpInName, sectionAndOrgs, chkOrg)
                    estWidth = estColLength(`${tmpIsOC}`, false)
                    return ({
                        headerName: tmpIsOC, 
                        field: tmpInName,
                        width: estWidth,
                        editable: editable,
                        hide: false,
                    }) 
                }
            }       
        }
    });
    if(incSection === true) {
        return processSections(columnNames, sectionAndOrgs, chkOrg)
    } else {
        return columnNames
    }
}
const processSections = (columnDefs, sectionAndOrgs = [], chkOrg = false) => {
    let tmpDefChildren = []
    each(columnDefs, (colValue) => {
        if (isEmpty(colValue.children) && !isEmpty(colValue.joinOn)) {
            tmpDefChildren.push(colValue)       
        }
    })
    remove(columnDefs, (tmpDefChildren) => {
        return (columnDefs.joinOn !== tmpDefChildren.joinOn)
    })
    each(tmpDefChildren, (tmpValue) => {
        find(columnDefs, (obj) => {
            if (obj.groupId === tmpValue.joinOn) {
                return obj.children.push(tmpValue)
            }
            // if (obj.headerName === tmpValue.joinOn) {
            //     return obj.children.push(tmpValue)
            // }
        })
    })

    if(chkOrg && has(sectionAndOrgs[0], 'orgCode')) {
        each(columnDefs, (value) => {
            find(sectionAndOrgs, (obj) => {
                if(obj.sectionCode === value.headerName) {
                    return value.headerName = `${value.headerName} : ${obj.orgCode}`
                }
            })
        })
    }

    return columnDefs
}

const estColLength = (inFieldName = '') => {
    const inLen = inFieldName.length
    switch (true) {
        case (inLen <= 3): {
            return inLen * 24
        }
        case (inLen <= 5) && (inLen > 3): {
            return inLen * 22
        }
        case (inLen <= 10) && (inLen > 5): {
            return inLen * 18
        }
        case (inLen <= 15) && (inLen > 10): {
            return inLen * 16
        }       
        case (inLen <= 20) && (inLen > 15): {
           return inLen * 15 
        }    
        case (inLen <= 30) && (inLen > 20): {
           return inLen * 15
        }
        default: {
            return inLen * 12
        }
    }
}

const estHeader = (sectionList, tmpSplitNames) => {
    let tmpHeaderName = ''
    if(sectionList.indexOf(tmpSplitNames[3]) > -1) {
    return `${tmpSplitNames[1]} ${replace(tmpSplitNames[2], ',', '')}` 
    } else {
        forOwn(tmpSplitNames, (value, key) => {
            if (!isEmpty(value) && key > 0) {
                tmpHeaderName += `${tmpSplitNames[key]} `
            }
        })
    }
    return tmpHeaderName
}
// TODO: Fix for EIT Codes
const isOfficeCode = (sectionList, inHeader = '', sectionAndOrgs = [], chkOrg = false) => {
    let tmpOfficeCode = ''
    let tmpDistrict  = []
    let outHeader = inHeader

    each(sectionList, (value) => {
        tmpDistrict.push(split(value, '-', 1))
    })
    tmpDistrict = uniq(flatten(tmpDistrict))
    tmpOfficeCode = replace(toUpper(startCase(inHeader)), new RegExp('[ ]', 'g'), '-')

    if(indexOf(tmpDistrict, split(tmpOfficeCode, '-', 1)[0]) > -1){
        outHeader = tmpOfficeCode
    }
    const testValue = replace(toUpper(startCase(tmpOfficeCode)), new RegExp('[-]', 'g'), ' ')
    if(indexOf(tmpDistrict, testValue) > -1) {
        outHeader = testValue
    }

    if(chkOrg && has(sectionAndOrgs[0], 'orgCode')) {
        find(sectionAndOrgs, (obj) => {
            if(obj.sectionCode === outHeader) {
                return outHeader = `${outHeader} : ${obj.orgCode}`
            }
        })
    }

    return outHeader 
}

const isOfficeSymbol = (inName = '', inDistricts = []) => {
    let tmpDist = []
    const tmpInColPart = split(toUpper(startCase(inName)), ' ', 1)
    
    if(indexOf(inDistricts, tmpInColPart[0]) === -1) {
        each(inDistricts, (value) => { 
            tmpDist = split(value, ' ')
            if(tmpDist[0] === tmpInColPart[0]) {
                each(tmpDist, (value, key) => {
                    if(key === 0) {
                        tmpInColPart[0] = value
                    } else {
                        tmpInColPart[0] += ` ${value}`
                    }
                })
            }
        })
    }
    return (indexOf(inDistricts, tmpInColPart[0]) > -1) ? {isbool: true, group: tmpInColPart[0]} : {isbool: false, group: tmpInColPart[0]}
}

const isDistrict = (inSections = []) => {
    let tmpDistrict = []
    each(inSections, (value) => {
        tmpDistrict.push(split(value, '-', 1))
    })
    tmpDistrict = uniq(flatten(tmpDistrict))
    return tmpDistrict
}

export default filterColumns
