/* eslint-disable no-unused-vars */
/* eslint-disable no-fallthrough */
import {
    split,
    has,
    concat,
    cloneDeep,
    each,
    find,
    takeRight,
    compact,
    isEmpty,
} from 'lodash'

const isDev = (process.env.NODE_ENV === 'development') ? true : false

export const pageByLocation = (inLoc = window.location.pathname, inHash = window.location.hash) => {
    let outPages = {main: '', sub: ''}

    switch(inLoc) {               
        case `${process.env.REACT_APP_ROOT}ssb/`: {
            outPages = {
                main: 'SSB Builder',
                sub: 'General Information'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}ssb/sv/`: {
            outPages = {
                main: 'SSB Builder',
                sub: 'Scope Development'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}ssb/sv/scope`: {
            outPages = {
                main: 'SSB Builder',
                sub: 'Scope Development'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}ssb/sv/commitment`: {
            outPages = {
                main: 'SSB Builder',
                sub: 'Resource Commitments'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}ssb/sv/budget`: {
            outPages = {
                main: 'SSB Builder',
                sub: 'Budget Review'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}ssb/sv/misclabor`: {
            outPages = {
                main: 'SSB Builder',
                sub: 'Miscellaneous & Outsourced '
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}ssb/sv/scheduling`: {
            outPages = {
                main: 'SSB Builder',
                sub: 'Scheduling',
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}mgmt/`: {
            outPages = {
                main: 'Product Management',
                sub: ''
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}mgmt/approvals/`: {
            outPages = {
                main: 'Product Management, Approvals',
                sub: 'Approvals'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}mgmt/approvals/ssbreview`: {
            outPages = {
                main: 'Product Management, Approvals',
                sub: ''
            }
            switch(inHash) {
                case '#scope': {
                    outPages = {
                        ...outPages, sub: 'Scope'
                    }
                    break;
                }
                case '#commitments': {
                    outPages = {
                        ...outPages, sub: 'Resource Commitments'
                    }
                    break;                    
                    
                }
                case '#miscellaneous': {
                    outPages = {
                        ...outPages, sub: 'Miscellaneous & Outsourced'
                    }
                    break;                    
                }
                case '#schedule': {
                    outPages = {
                        ...outPages, sub: 'Schedule'
                    }
                    break;                    
                }
                case '#budget': {
                    outPages = {
                        ...outPages, sub: 'Budget Review'
                    }
                    break;                    
                }
                case '#tracking': {
                    outPages = {
                        ...outPages, sub: 'Tracking'
                    }
                    break;                    
                }
                default: break;
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}mgmt/approvals/resreview`: {
            outPages = {
                main: 'Product Management, Approvals',
                sub: 'Resource Review'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}mgmt/status/`: {
            outPages = {
                main: 'Product Management',
                sub: 'Status & Tracking'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}mgmt/tracking/`: {
            outPages = {
                main: 'Product Management',
                sub: 'Budget Tracking'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}mgmt/versions/`: {
            outPages = {
                main: 'Product Management',
                sub: 'Version Control'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}mgmt/expense/`: {
            outPages = {
                main: 'Product Management',
                sub: 'Expense Report'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}ems/`: {
            outPages = {
                main: 'EMS Configuration',
                sub: 'EMS Configuration'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}ems/configuration/`: {
            outPages = {
                main: 'EMS Configuration',
                sub: 'EMS Configuration'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}ems/configuration/product`: {
            outPages = {
                main: 'EMS Configuration',
                sub: 'EMS Configuration: Product'
            }
            switch(inHash) {
                case '#versions': {
                    outPages = {
                        ...outPages, sub: 'Product Management'
                    }
                    break;
                }
                default: break;
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}ems/configuration/organization`: {
            outPages = {
                main: 'EMS Configuration',
                sub: 'EMS Configuration: Organization'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}ems/configuration/general`: {
            outPages = {
                main: 'EMS Configuration',
                sub: 'EMS Configuration: General'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}ems/configuration/resources`: {
            outPages = {
                main: 'EMS Configuration',
                sub: 'EMS Configuration: Resources'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}ems/configuration/taxes`: {
            outPages = {
                main: 'EMS Configuration',
                sub: 'EMS Configuration: Taxes'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}ems/configuration/roles`: {
            outPages = {
                main: 'EMS Configuration',
                sub: 'EMS Configuration: Roles'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}ems/administration/`: {
            outPages = {
                main: 'EMS Administration',
                sub: 'EMS Administration'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}rpt/`: {
            outPages = {
                main: 'Reporting',
                sub: ''
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}`: {
            outPages = {
                main: 'EMS Application Root',
                sub: ''
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}rpt/resource`: {
            outPages = {
                main: 'Reporting',
                sub: 'Resource Report'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}rpt/expense`: {
            outPages = {
                main: 'Reporting',
                sub: 'Expense Report'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}rpt/workload`: {
            outPages = {
                main: 'Reporting',
                sub: 'Workload Report'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}rpt/sitrep`: {
            outPages = {
                main: 'Reporting',
                sub: 'SitRep'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}rpt/tracking`: {
            outPages = {
                main: 'Reporting',
                sub: 'Product Tracking'
            }
            return outPages
        }
        case `${process.env.REACT_APP_ROOT}rpt/proga`: {
            outPages = {
                main: 'Reporting',
                sub: 'Program Analysis'
            }
            return outPages           
        }
        case `${process.env.REACT_APP_ROOT}rpt/lbalance`: {
            outPages = {
                main: 'Reporting',
                sub: 'Labor Balances'
            }
            return outPages
        }     
        default: {
            outPages = {
                main: '',
                sub: ''
            }
            return outPages
        } 
    }
}

export const accessByLocation = (inLoc = window.location, roles = [], enforced) => {
    const inHash = inLoc.hash
    const basePerm = ['global', 'admin', 'ems', 'configurations']
    const mSSB = ['ssb', 'productInfo', 'sitrep']
    const mMGMT = ['management', 'ssb', 'productInfo', 'sitrep']
    const pageLoc = pageByLocation(inLoc.pathname)
    const segments = compact(split(inLoc.pathname, '/'))
    const baseTarget = (segments.length - (compact(split(process.env.REACT_APP_ROOT, '/')).length))
    const baseLoc = takeRight(segments, baseTarget)[0]

    let outAccess = {
        enforced: enforced,
        location: inLoc.pathname,
        hash: inLoc.hash,
        baseLoc: baseLoc,
        segments: segments,
        main: pageLoc.main, 
        sub: pageLoc.sub,
        proc: '',
        hasError: false,
        msgError: '',
        module: '', // Used for initial module access root check, should be field name
        modules: basePerm,
        moduleAccess: false,
        roles: roles,
        adminAccess: false,
        userAccess: false,
        deniedAccess: false,
    }

    if(roles.length < 1) {
        return outAccess = {...outAccess, hasError: true, msgError: 'Error, No User Roles', proc: 'accessByLocation'} 
    } else {
        switch(baseLoc) {
            case 'ssb': {
                outAccess = {
                    ...outAccess,
                    modules: concat(basePerm, mSSB, ['pep', 'pdt']),
                    module: 'ssb'
                }
                if(baseTarget === 2) {
                    switch(takeRight(outAccess.segments, (baseTarget - 1))[0]) {
                        case '': {
                            break;
                        }
                        case 'sv': {
                            outAccess = {
                                ...outAccess,
                                main: 'SSB Builder',
                                sub: 'No Module',
                                modules: concat(basePerm, mSSB),
                                module: 'ssb',
                                hasError: true, 
                                msgError: 'Error, No Module', 
                                proc: 'accessByLocation',
                            }
                            break;
                        }
                        default: {
                            outAccess = {
                                ...outAccess,
                                sub: 'Unknown Location',
                                modules: concat(basePerm, mSSB),
                                module: 'ssb',
                                hasError: true, 
                                msgError: 'Error, Unknown Location', 
                                proc: 'accessByLocation',

                            }
                        }
                    }
                } 
                if(baseTarget === 3) {
                    switch(takeRight(outAccess.segments, (baseTarget - 2))[0]) {
                        case 'scope': {
                            outAccess = {
                                ...outAccess,
                                modules: concat(basePerm, mSSB, ['wbs']),
                                module: 'wbs',
                            }
                            break;
                        }
                        case 'commitment': {
                            outAccess = {
                                ...outAccess,
                                modules: concat(basePerm, mSSB, ['commitments']),
                                module: 'commitments'
                            }
                            break;
                        }
                        case 'scheduling': {
                            outAccess = {
                                ...outAccess,
                                modules: concat(basePerm, mSSB, ['scheduling']),
                                module: 'scheduling'
                            }
                            break;
                        }
                        case 'misclabor': {
                            outAccess = {
                                ...outAccess,
                                modules: concat(basePerm, mSSB, ['mb', 'ow']),
                                module: 'ssb'
                            }
                            break;
                        }
                        case 'budget': {
                            outAccess = {
                                ...outAccess,
                                modules: concat(basePerm, mSSB),
                                module: 'ssb'
                            }
                            break;
                        }
                        default: {
                            outAccess = {
                                ...outAccess,
                                sub: 'Unknown Location',
                                modules: concat(basePerm, mSSB),
                                module: 'ssb',
                                hasError: true, 
                                msgError: 'Error, Unknown Location', 
                                proc: 'accessByLocation'
                            }
                        }
                    }
                }
                break;
            } // SSB
            case 'mgmt': {
                outAccess = {
                    ...outAccess,
                    modules: concat(basePerm, mMGMT),
                    module: 'management'
                }
                if(baseTarget === 2) {
                    switch(takeRight(outAccess.segments, (baseTarget - 1))[0]) {
                        case '': {
                            break;
                        }
                        case 'status': {
                            outAccess = {
                                ...outAccess,
                                modules: concat(outAccess.modules, ['status']),
                                module: 'status'
                            }
                            break;
                        }
                        case 'tracking': {
                            outAccess = {
                                ...outAccess,
                                modules: concat(outAccess.modules, ['pracTracking']),
                                module: 'pracTracking'
                            }
                            break;
                        }
                        case 'versions': {
                            outAccess = {
                                ...outAccess,
                                modules: concat(outAccess.modules, ['versionControl']),
                                module: 'versionControl'
                            }
                            break;
                        }
                        case 'expense': {
                            outAccess = {
                                ...outAccess,
                                modules: concat(outAccess.modules, ['reportal']),
                                module: 'reportal'
                            }
                            break;
                        }
                        case 'approvals': {
                            outAccess = {
                                ...outAccess,
                                modules: concat(outAccess.modules, ['approvals']),
                                module: 'approvals'
                            }
                            break;
                        }
                        default: {                            
                            outAccess = {
                                ...outAccess,
                                sub: 'Unknown Location',
                                modules: concat(basePerm, mMGMT),
                                module: 'management',
                                hasError: true, 
                                msgError: 'Error, Unknown Location', 
                                proc: 'accessByLocation',
                            }
                        }
                    } 
                }
                if(baseTarget === 3) {
                    switch(takeRight(outAccess.segments, (baseTarget - 2))[0]) {
                        case '': {
                            break;
                        }
                        case 'resreview': {
                            outAccess = {
                                ...outAccess,
                                modules: concat(outAccess.modules, ['approvals']),
                                module: 'approvals'
                            }
                            break;
                        }
                        case 'ssbreview': {
                            if(isEmpty(inHash)) {
                                outAccess = {
                                    ...outAccess,
                                    modules: concat(outAccess.modules, ['approvals']),
                                    module: 'approvals'
                                }
                                break;
                            } else {
                                
                                switch(inHash) {
                                    case '#scope': 
                                    case '#commitments':
                                    case '#miscellaneous':
                                    case '#schedule':
                                    case '#tracking':
                                    case '#budget': {
                                        outAccess = {
                                            ...outAccess,
                                            modules: concat(outAccess.modules, ['approvals']),
                                            module: 'approvals',
                                            sub: inHash
                                        }
                                        break;
                                    }
                                    default: {
                                        outAccess = {
                                            ...outAccess,
                                            modules: concat(basePerm, ['management']),
                                            module: 'management',
                                            hasError: true, 
                                            msgError: 'Error, Unknown Hash Location', 
                                            proc: 'accessByLocation',
                                            sub: inHash,
                                        }
                                    }
                                }

                            }                           
                        }
                        case 'ssbreview#scope': { // even when window.pathname doesn't display hash, it's still accounted for, dumb.
                            break;
                        }
                        case 'ssbreview#commitments': {
                            break;
                        }
                        case 'ssbreview#miscellaneous': {
                            break;
                        }
                        case 'ssbreview#schedule': {
                            break;
                        }
                        case 'ssbreview#budget': {
                            break;
                        }
                        case 'ssbreview#tracking': {
                            break;
                        }
                        default: { //
                            outAccess = {
                                ...outAccess,
                                sub: 'Unknown Location',
                                modules: concat(basePerm, ['management']),
                                module: 'management',
                                hasError: true, 
                                msgError: 'Error, Unknown Location', 
                                proc: 'accessByLocation',
                            }
                        }
                    }
                }
                break;
            } // MGMT
            case 'rpt': {
                outAccess = {
                    ...outAccess,
                    modules: concat(outAccess.modules, ['reportal']),
                    module: 'reportal'
                }
                break;
            }
            case 'ems': {
                outAccess = {
                    ...outAccess,
                    modules: concat(outAccess.modules, ['roles', 'productCreation', 'productTitle']),
                    module: 'configurations'
                }
                if(baseTarget === 2) {
                    switch(takeRight(outAccess.segments, (baseTarget - 1))[0]) {
                        case '': {
                            break;
                        }
                        case 'configuration': {
                            outAccess = {
                                ...outAccess,
                                modules: outAccess.modules, // Nothing for now
                                module: 'configurations'
                            }
                            break;
                        }

                        case 'administration': {
                            outAccess = {
                                ...outAccess,
                                modules: outAccess.modules, // Nothing for now
                                module: 'configurations'
                            }
                            break;
                        }
                        default: {                            
                            outAccess = {
                                ...outAccess,
                                sub: 'Unknown Location',
                                modules: outAccess.modules,
                                module: 'configurations',
                                hasError: true, 
                                msgError: 'Error, Unknown Location', 
                                proc: 'accessByLocation',
                            }
                        }
                    } 
                }
                if(baseTarget === 3) {
                    switch(takeRight(outAccess.segments, (baseTarget - 2))[0]) {
                        case '': {
                            break;
                        }

                        case 'product': {
                            if(isEmpty(inHash)) {
                                outAccess = {
                                    ...outAccess,
                                    modules: outAccess.modules, // Nothing for now
                                    module: 'configurations'
                                }
                            }  else {
                                
                                switch(inHash) {
                                    case '#versions': {
                                        outAccess = {
                                            ...outAccess,
                                            modules: outAccess.modules,
                                            module: 'configurations',
                                            sub: inHash
                                        }
                                        break;
                                    }
                                    default: {
                                        outAccess = {
                                            ...outAccess,
                                            modules: concat(basePerm, ['roles', 'productCreation', 'productTitle']),
                                            module: 'configurations',
                                            hasError: true, 
                                            msgError: 'Error, Unknown Hash Location', 
                                            proc: 'accessByLocation',
                                            sub: inHash,
                                        }
                                    }
                                }
                            } 
                            break;
                        }

                        case 'product#versions': { // even when window.pathname doesn't display hash, it's still accounted for.
                            break;
                        }

                        case 'organization': {
                            if(isEmpty(inHash)) {
                                outAccess = {
                                    ...outAccess,
                                    modules: outAccess.modules, // Nothing for now
                                    module: 'configurations'
                                }
                            }  else {
                                
                                switch(inHash) {
                                    // case '#': {
                                    //     outAccess = {
                                    //         ...outAccess,
                                    //         modules: concat(outAccess.modules),
                                    //         module: 'configurations',
                                    //         sub: inHash
                                    //     }
                                    //     break;
                                    // }
                                    default: {
                                        outAccess = {
                                            ...outAccess,
                                            modules: concat(basePerm, ['roles', 'productCreation', 'productTitle']),
                                            module: 'configurations',
                                            hasError: true, 
                                            msgError: 'Error, Unknown Hash Location', 
                                            proc: 'accessByLocation',
                                            sub: inHash,
                                        }
                                    }
                                }
                            } 
                            break;
                        }

                        case 'general': {
                            if(isEmpty(inHash)) {
                                outAccess = {
                                    ...outAccess,
                                    modules: outAccess.modules, // Nothing for now
                                    module: 'configurations'
                                }
                            }  else {
                                
                                switch(inHash) {
                                    // case '#': {
                                    //     outAccess = {
                                    //         ...outAccess,
                                    //         modules: concat(outAccess.modules),
                                    //         module: 'configurations',
                                    //         sub: inHash
                                    //     }
                                    //     break;
                                    // }
                                    default: {
                                        outAccess = {
                                            ...outAccess,
                                            modules: concat(basePerm, ['roles', 'productCreation', 'productTitle']),
                                            module: 'configurations',
                                            hasError: true, 
                                            msgError: 'Error, Unknown Hash Location', 
                                            proc: 'accessByLocation',
                                            sub: inHash,
                                        }
                                    }
                                }
                            } 
                            break;
                        }

                        case 'resources': {
                            if(isEmpty(inHash)) {
                                outAccess = {
                                    ...outAccess,
                                    modules: outAccess.modules, // Nothing for now
                                    module: 'configurations'
                                }
                            }  else {
                                
                                switch(inHash) {
                                    // case '#': {
                                    //     outAccess = {
                                    //         ...outAccess,
                                    //         modules: outAccess.modules, // Nothing for now
                                    //         module: 'configurations',
                                    //         sub: inHash
                                    //     }
                                    //     break;
                                    // }
                                    default: {
                                        outAccess = {
                                            ...outAccess,
                                            modules: concat(basePerm, ['roles', 'productCreation', 'productTitle']),
                                            module: 'configurations',
                                            hasError: true, 
                                            msgError: 'Error, Unknown Hash Location', 
                                            proc: 'accessByLocation',
                                            sub: inHash,
                                        }
                                    }
                                }
                            } 
                            break;
                        }

                        case 'taxes': {
                            if(isEmpty(inHash)) {
                                outAccess = {
                                    ...outAccess,
                                    modules: outAccess.modules, // Nothing for now
                                    module: 'configurations'
                                }
                            }  else {
                                
                                switch(inHash) {
                                    // case '#': {
                                    //     outAccess = {
                                    //         ...outAccess,
                                    //         modules: outAccess.modules, // Nothing for now
                                    //         module: 'configurations',
                                    //         sub: inHash
                                    //     }
                                    //     break;
                                    // }
                                    default: {
                                        outAccess = {
                                            ...outAccess,
                                            modules: concat(basePerm, ['roles', 'productCreation', 'productTitle']),
                                            module: 'configurations',
                                            hasError: true, 
                                            msgError: 'Error, Unknown Hash Location', 
                                            proc: 'accessByLocation',
                                            sub: inHash,
                                        }
                                    }
                                }
                            } 
                            break;
                        }

                        case 'roles': {
                            if(isEmpty(inHash)) {
                                outAccess = {
                                    ...outAccess,
                                    modules: outAccess.modules, // Nothing for now
                                    module: 'configurations'
                                }
                            }  else {
                                
                                switch(inHash) {
                                    // case '#': {
                                    //     outAccess = {
                                    //         ...outAccess,
                                    //         modules: outAccess.modules, // Nothing for now
                                    //         module: 'configurations',
                                    //         sub: inHash
                                    //     }
                                    //     break;
                                    // }
                                    default: {
                                        outAccess = {
                                            ...outAccess,
                                            modules: concat(basePerm, ['roles', 'productCreation', 'productTitle']),
                                            module: 'configurations',
                                            hasError: true, 
                                            msgError: 'Error, Unknown Hash Location', 
                                            proc: 'accessByLocation',
                                            sub: inHash,
                                        }
                                    }
                                }
                            } 
                            break;
                        }                        
                        // Admin Cases
                        case 'system': {
                            if(isEmpty(inHash)) {
                                outAccess = {
                                    ...outAccess,
                                    modules: outAccess.modules, // Nothing for now
                                    module: 'configurations'
                                }
                            } else { 
                                switch(inHash) {
                                    default: {
                                        outAccess = {
                                            ...outAccess,
                                            modules: concat(basePerm, ['roles', 'productCreation', 'productTitle']),
                                            module: 'configurations',
                                            hasError: true, 
                                            msgError: 'Error, Unknown Hash Location', 
                                            proc: 'accessByLocation',
                                            sub: inHash,
                                        }
                                    }
                                }
                            }
                            break;
                        }

                        default: {
                            outAccess = {
                                ...outAccess,
                                sub: 'Unknown Location',
                                modules: concat(basePerm, ['roles', 'productCreation', 'productTitle']),
                                module: 'configurations',
                                hasError: true, 
                                msgError: 'Error, Unknown Location', 
                                proc: 'accessByLocation',
                            }
                        }
                    }
                }
                break;  
            }            
            default: { // should be root or archive
                outAccess = {
                    ...outAccess,
                    main: 'Global',
                    sub: 'Global',
                    module: 'ems'
                }
            }
        }
    }
    return marryRoleToLoc(outAccess)
}

const marryRoleToLoc = (outAccess = {}) => {
    let tmpMods = []
    let outObj = cloneDeep(outAccess)

    if(outObj.roles.length < 1 || !has(outObj, 'baseLoc')) {
        return outObj = {...outObj, hasError: true, msgError: 'Error, No User Roles, or No Defined Base Location', proc: 'marryRoleToLoc'} 
    } else if (has(outObj, 'baseLoc')) {
        each(outObj.modules, (value) => {
            tmpMods.push(find(outAccess.roles, (obj) => {return obj.role === value}))
            // if(outObj.enforced) {
            //     tmpMods.push(find(outAccess.roles, (obj) => {return obj.role === value}))
            // } 
        })
        outObj.modules = compact(tmpMods)
        //  outObj.modules = (outObj.enforced) ? compact(tmpMods) : outAccess.roles
    }
    return outObj 
}

