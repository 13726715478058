import {
    keysIn,
    startCase,
    forOwn,
    isEmpty,
    indexOf,
    split,
    pullAll,
    each,
    remove,
    find,
    replace,
    uniq,
    toUpper,
    flatten, 
    } from 'lodash'

const filterTrackingColumns = (response = {}, readInSections = [], incSection = false, editable = false, alt = false) => {
    // Add items to this list that you do not want displayed, items must also have a column definition below.
    const filter = [
        'approp',
        'category',
        'element',
        'fadNo',
        'farOrdNo',
        'fundAcct',
        'fwiTitle',
        'indicator',
        'owiCode',
        'owiTitle',
        'p2ProjCo',
        'p2ProjNa',
        'p2ProjNo',
        'p2TaskNa',
        'p2TaskNo',
        'inc', // arbitrary incrementor
        //
        'taskId',
        'p2ProjName',
        'projectNo',
        'projectId',
        'wiName',
        'wiCode',
        'hier',
        'hierRef',
        'arrHier',
        'activeInactiveInd',
        'focused',
        'altUniq'

] 
    // Used for comparison on section columns, need to make this more efficient eventually.
    const sectionList = readInSections
    const districtList = isDistrict(sectionList) // TODO: Try to future proof the comparison
    
    // Gather header names
    // Check to see if headers are seperated from row-data where header key will equal row-data field_name
    // Not full map included in header, will use as a lookup.
    let empIdHeader = []

    if(response.data.Header){ 
        empIdHeader = response.data.Header
    }

   let inKeys = []
    if(response.data.Data) {
        inKeys = keysIn(response.data.Data[0])
    } else {
        inKeys = keysIn(response.data[0])
    }

    // Try to track group / child creation blindly
    let tmpCnt = 0
    let tmpLastGroupName = ''
    let tmpGroupHolder = [] // Now not blindly
    let estWidth = 0

    const columnNames = inKeys.map(inName => { 
        const strCaseHeaderName = startCase(inName)       
        const doHide = (filter.indexOf(inName) > -1) // If columnname exists in the filter array mark hide = true
        // Column definitions
        switch (inName) {
            case 'inc' : {
                return ({
                    headerName: 'Increment', 
                    headerTooltip: 'Increment',
                    tooltipField: 'inc',
                    field: 'inc', 
                    width: 10, 
                    hide: true,
                    editable: false,
                    suppressSizeToFit: true,
                })
            }
            case 'approp' : {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName,
                    field: inName, 
                    width: 100, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                })
            }
            case 'category' : {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 50, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                })
            }
            case 'chargeCode' : {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 90, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true,
                })
            }             
            case 'describe' : {
                return ({
                    headerName: (alt) ? 'PR&C Description' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'PR&C Description' : strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 350, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                })
            }             
            case 'element' : {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 50, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                })
            }             
            case 'fadNo' : {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 50, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                })
            }             
            case 'farOrdNo' : {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 50, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                })
            }             
            case 'fundAcct' : {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 50, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                })
            }             
            case 'funded' : {
                return ({
                    headerName: (alt) ? 'Work Item' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'Work Item' : strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 100, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true,
                })
            }
            //
            case 'taskId' : {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 50, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true,
                })
            }
            case 'p2ProjName' : {
                return ({
                    headerName: (alt) ? 'P2 Project' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'P2 Project' : strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 200, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true,
                })
            }
            case 'projectNo' : {
                return ({
                    headerName: (alt) ? 'Project Number' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'Project Number' : strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 50, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true,
                })
            }
            case 'projectId' : {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 50, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true,
                })
            }
            case 'wiName' : {
                return ({
                    headerName: (alt) ? 'Work Item Name' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'Work Item Name' : strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 250, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true,
                })
            }            
            case 'wiCode' : {
                return ({
                    headerName: (alt) ? 'Work Item Code' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'Work Item Code' : strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 100, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true,
                })
            }
            case 'hier' : {
                return ({
                    headerName: (alt) ? 'Hierarchy' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'Hierarchy' : strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 100, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true,
                })
            }
            case 'hierRef' : {
                return ({
                    headerName: (alt) ? 'Hierarchy' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'Hierarchy' : strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 100, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true,
                })
            }
            case 'arrHier' : {
                return ({
                    headerName: (alt) ? 'Hierarchy' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'Hierarchy' : strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 100, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true,
                })
            }
            case 'activeInactiveInd': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 50, 
                    hide: doHide
                })
            }
            case 'focused': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 50, 
                    hide: doHide
                })
            }
            case 'altUniq': {
                return ({
                    headerName: strCaseHeaderName, 
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 50, 
                    hide: doHide
                })
            }     
            //
            case 'resourceCode' : {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 120, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true,
                })
            }
            case 'sourceFoa' : {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 60, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true,
                })
            }                             
            case 'fwiTitle' : {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 500, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                })
            }             
            case 'indicator' : {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 50, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                })
            }              
            case 'orgCode': {
                return ({
                    headerName: (alt) ? 'Performing ORG' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'Performing ORG' : strCaseHeaderName,
                    tooltipField: inName,
                    field: inName, 
                    width: 90, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true,
                })
            }    
            case 'owiCode' : {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 50, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                })
            }
            case 'owiTitle' : {
                return ({
                    headerName: strCaseHeaderName,
                    headerTooltip: strCaseHeaderName,
                    tooltipField: inName,
                    field: inName, 
                    width: 50, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                })
            }    
            case 'p2ProjCo' : {
                return ({
                    headerName: (alt) ? 'P2 Project Code ' :  strCaseHeaderName,
                    headerTooltip: (alt) ? 'P2 Project Code ' :  strCaseHeaderName,
                    tooltipField: inName,
                    field: inName, 
                    width: 50, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                })
            }    
            case 'p2ProjNa' : {
                return ({
                    headerName: (alt) ? 'P2 Project Name' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'P2 Project Name' : strCaseHeaderName,
                    tooltipField: inName,
                    field: inName, 
                    width: 50, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                })
            }    
            case 'p2ProjNo' : {
                return ({
                    headerName: (alt) ? 'P2 Project Num' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'P2 Project Num' : strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 50, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                })
            }    
            case 'p2TaskNa' : {
                return ({
                    headerName: (alt) ? 'P2 Task Name' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'P2 Task Name' : strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 50, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                })
            }    
            case 'p2TaskNo' : {
                return ({
                    headerName: (alt) ? 'P2 Task Number' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'P2 Task Number' : strCaseHeaderName,
                    tooltipField: inName,
                    field: inName, 
                    width: 50, 
                    hide: doHide,
                    editable: editable,
                    suppressSizeToFit: true,
                })
            }    
            case 'pracLine' : {
                return ({
                    headerName: (alt) ? 'PR&C Line No.' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'PR&C Line No.' : strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 65, 
                    hide: doHide,
                    editable: editable,
                   // suppressSizeToFit: true,
                })
            }    
            case 'pracNo' : {
                return ({
                    headerName: (alt) ? 'PR&C' : strCaseHeaderName,
                    headerTooltip: (alt) ? 'PR&C' : strCaseHeaderName,
                    tooltipField: inName, 
                    field: inName, 
                    width: 150, 
                    hide: doHide,
                    editable: editable,
                    // suppressSizeToFit: true,
                    
                })
            }                                                                                                                                                                          
            default: {
                let tmpInName = inName 
                forOwn(empIdHeader, (value, key) => {
                    if(inName === key) { 
                        return inName = value
                    }
                })

                const tmpIsOs = isOfficeSymbol(inName, districtList) // 

                if(tmpIsOs.isbool && incSection === true) {

                        let tmpSplitNames = split(inName, ' ', 10)

                        forOwn(tmpSplitNames, (value, key) => {
                            (isEmpty(value)) ? tmpSplitNames[key] = '' : tmpSplitNames[key] = value
                        })
                        
                        if(indexOf(tmpSplitNames[0], '-') === -1) {
                            if(tmpIsOs.group !== tmpSplitNames[0]) {
                                tmpSplitNames[0] = tmpIsOs.group
                            }
                            let tmpIdx = split(tmpIsOs.group, ' ')
                            tmpSplitNames = pullAll(tmpSplitNames, tmpIdx)                        
                        }

                        let tmpHeaderName = ''
                        let ctrlColGroupShow = 'open'
                        let tmpStructure = {}                        
                    
                        if(tmpLastGroupName !== tmpSplitNames[0]) { 

                            if(!isEmpty(tmpLastGroupName) && 
                                (tmpLastGroupName !== tmpSplitNames[0]) && 
                                (tmpGroupHolder.indexOf(tmpSplitNames[0]) === -1)) { 
                                    tmpCnt = 0
                            }                            
                            tmpLastGroupName = tmpSplitNames[0]
                        }            
                        tmpCnt++
                        if(tmpCnt === 1 && tmpGroupHolder.indexOf(tmpSplitNames[0] === -1)) {
                            tmpGroupHolder.push(tmpSplitNames[0]) 
                            tmpHeaderName = estHeader(sectionList, tmpSplitNames)
                            let estWidth
                            (tmpSplitNames[0].length > tmpHeaderName.length) ? estWidth = estColLength(`${tmpSplitNames[0]}`) : estWidth = estColLength(`${tmpHeaderName}`)
                            tmpStructure = {
                                headerName: tmpSplitNames[0],
                                headerTooltip: tmpSplitNames[0],
                                groupId: tmpSplitNames[0],
                                openByDefault: true,
                                marryChildren: true,
                                width: estColLength(`${tmpSplitNames[0]}`),
                                children: [{
                                    joinOn: tmpSplitNames[0], 
                                    headerName: `${tmpHeaderName}`,
                                    tooltipField: tmpInName, 
                                    field: tmpInName,
                                    width: estWidth,
                                    editable: editable,
                                    hide: false,
                                    cellStyle: {
                                        textAlign: 'center' 
                                    } 
                                }]
                            }
                        } else {
                            tmpHeaderName = estHeader(sectionList, tmpSplitNames)
                            estWidth = estColLength(`${tmpHeaderName}`)
                            tmpStructure = {
                                joinOn: tmpSplitNames[0],                                    
                                headerName: `${tmpHeaderName}`,
                                headerTooltip: `${tmpHeaderName}`,
                                tooltipField: tmpInName, 
                                field: tmpInName,
                                width: estWidth,
                                editable: editable,
                                hide: false,
                                columnGroupShow: `${ctrlColGroupShow}`,
                                filter: 'agNumberColumnFilter', 
                                cellStyle: {
                                    textAlign: 'center' 
                                },
                            }
                        }
                        return tmpStructure                            
                } else {
                    const tmpIsOC =  isOfficeCode(sectionList, tmpInName)
                    estWidth = estColLength(`${tmpIsOC}`, false)
                    return ({
                        headerName: tmpIsOC,
                        headerTooltip: tmpIsOC,
                        tooltipField: tmpInName,  
                        field: tmpInName,
                        width: estWidth,
                        editable: editable,
                        hide: false,
                    }) 
                }
            }       
        }
    });
    if(incSection === true) {
        return processSections(columnNames)
    } else {
        return columnNames
    }
}

const processSections = (columnDefs) => {
    let tmpDefChildren = []
    each(columnDefs, (colValue) => {
        if (isEmpty(colValue.children) && !isEmpty(colValue.joinOn)) {
            tmpDefChildren.push(colValue)       
        }
    })
    remove(columnDefs, (tmpDefChildren) => {
        return (columnDefs.joinOn !== tmpDefChildren.joinOn)
    })
    each(tmpDefChildren, (tmpValue) => {
        find(columnDefs, (obj) => {
            if (obj.headerName === tmpValue.joinOn) {
                return obj.children.push(tmpValue)
            }
        })
    })
    return columnDefs
}

const estColLength = (inFieldName = '') => {
    const inLen = inFieldName.length
    switch (true) {
        case (inLen <= 3): {
            return inLen * 22
        }
        case (inLen <= 5) && (inLen > 3): {
            return inLen * 18
        }
        case (inLen <= 10) && (inLen > 5): {
            return inLen * 14
        }
        case (inLen <= 15) && (inLen > 10): {
            return inLen * 13
        }       
        case (inLen <= 20) && (inLen > 15): {
           return inLen * 11 
        }    
        case (inLen <= 30) && (inLen > 20): {
           return inLen * 11
        }
        default: {
            return inLen * 10
        }
    }
}

const estHeader = (sectionList, tmpSplitNames) => {
    let tmpHeaderName = ''
    if(sectionList.indexOf(tmpSplitNames[3]) > -1) {
    return `${tmpSplitNames[1]} ${replace(tmpSplitNames[2], ',', '')}` 
    } else {
        forOwn(tmpSplitNames, (value, key) => {
            if (!isEmpty(value) && key > 0) {
                tmpHeaderName += `${tmpSplitNames[key]} `
            }
        })
    }
    return tmpHeaderName
}

const isOfficeCode = (sectionList, inHeader = '') => {
    let tmpOfficeCode = ''
    let tmpDistrict  = []
    let outHeader = inHeader

    each(sectionList, (value) => {
        tmpDistrict.push(split(value, '-', 1))
    })
    tmpDistrict = uniq(flatten(tmpDistrict))
    tmpOfficeCode = replace(toUpper(startCase(inHeader)), new RegExp('[ ]', 'g'), '-')

    if(indexOf(tmpDistrict, split(tmpOfficeCode, '-', 1)[0]) > -1){
        outHeader = tmpOfficeCode
    }
    const testValue = replace(toUpper(startCase(tmpOfficeCode)), new RegExp('[-]', 'g'), ' ')
    if(indexOf(tmpDistrict, testValue) > -1) {
        outHeader = testValue
    }
    return outHeader 
}

const isOfficeSymbol = (inName = '', inDistricts = []) => {
    let tmpDist = []
    const tmpInColPart = split(toUpper(startCase(inName)), ' ', 1)
    
    if(indexOf(inDistricts, tmpInColPart[0]) === -1) {
        each(inDistricts, (value) => { 
            tmpDist = split(value, ' ')
            if(tmpDist[0] === tmpInColPart[0]) {
                each(tmpDist, (value, key) => {
                    if(key === 0) {
                        tmpInColPart[0] = value
                    } else {
                        tmpInColPart[0] += ` ${value}`
                    }
                })
            }
        })
    } 
    return (indexOf(inDistricts, tmpInColPart[0]) > -1) ? {isbool: true, group: tmpInColPart[0]} : {isbool: false, group: tmpInColPart[0]}
}

const isDistrict = (inSections = []) => {
    let tmpDistrict = []
    each(inSections, (value) => {
        tmpDistrict.push(split(value, '-', 1))
    })
    tmpDistrict = uniq(flatten(tmpDistrict))
    return tmpDistrict
}

export default filterTrackingColumns
